import { useState, useEffect } from 'react';

import router from 'next/router';
import Image from 'next/image';

import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery, Stack } from '@mui/material';

import CustomButton from '../components/Common/CustomButton.component';

const Custom404 = () => {
  const [countDown, setCountDown] = useState(5);

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let interval = null;
    if (countDown === 0) {
      handleGoBackToHomePage();
    } else {
      interval = setInterval(() => setCountDown(countDown - 1), 1000);
    }
    return () => clearInterval(interval);
  }, [countDown]);

  const handleGoBackToHomePage = () => {
    router.push('/');
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={4}
      sx={{ minHeight: '77vh' }}
    >
      <Typography variant={matchesXS ? 'h6' : 'h4'}>
        Chúng tôi không thể tìm thấy trang bạn cần... :((
      </Typography>
      <div className="not-found-image">
        <Image
          src="/static/images/404.svg"
          width="500px"
          height="280px"
          alt="404 image"
        ></Image>
      </div>
      <Stack alignItems="center" spacing={2}>
        <Typography>Tuổi trẻ nào mà chẳng đôi lần lạc lối...</Typography>
        <Typography>Tìm lại chính mình trong </Typography>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          sx={{ color: theme.palette.primary.main }}
        >
          {countDown}
        </Typography>
        <Typography>giây nữa</Typography>
        <CustomButton
          variant="contained"
          size="large"
          onClick={handleGoBackToHomePage}
        >
          Tìm lại ngay
        </CustomButton>
      </Stack>
    </Stack>
  );
};

export default Custom404;
